import { tsParticles } from "tsparticles-engine";
import { loadConfettiPreset } from "tsparticles-preset-confetti";

(async () => {
    await loadConfettiPreset(tsParticles); // this is required only if you are not using the bundle script

    tsParticles.load("tsparticles", {
        preset: "confetti",
        particles: {
            color: {
                value: ["#1E00FF", "#FF0061", "#E1FF00", "#00FF9E"],
            },
        },
        emitters: [
            {
                life: {
                    duration: 0,
                },
                position: {
                    x: 0,
                    y: 40,
                },
                particles: {
                    move: {
                        direction: "top-right",
                    },
                },
            },
            {
                life: {
                    duration: 0,
                },
                position: {
                    x: 100,
                    y: 40,
                },
                particles: {
                    move: {
                        direction: "top-left",
                    },
                },
            },
        ],
    });
})();
